import { FC, memo, useEffect } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';
import {
  renderMetaTags,
  SeoOrFaviconTag,
  TitleMetaLinkTag,
} from 'react-datocms';
import type { NextSeoProps } from 'next-seo';
import { track } from '@amplitude/analytics-browser';
import type { SeoMetaTag } from '@framework/api/types';
import {
  SUPPORTED_LOCALES,
  ROUTE_PRODUCT,
  ROUTE_CART,
  ROUTE_BUILD,
  ROUTE_BUILD_PC,
} from '@constants';

type Props = NextSeoProps & {
  cmsData?: SeoMetaTag[];
  canonical: string;
  baseUrl: string;
};

type LangAlternate = {
  hrefLang: string;
  href: string;
};

const buildLanguageAlternates = (
  baseUrl: string,
  canonical: string
): LangAlternate[] => {
  const alts = SUPPORTED_LOCALES.map(locale => ({
    hrefLang: locale,
    href: canonical.replace(baseUrl, `${baseUrl}/${locale}`),
  }));

  alts.push({ hrefLang: 'x-default', href: canonical });

  return alts;
};

const proxyOgImages = (
  cmsData: SeoMetaTag[] | SeoOrFaviconTag[]
): TitleMetaLinkTag[] => {
  // eslint-disable-next-line security/detect-non-literal-regexp
  const regex = new RegExp(`${process.env.NEXT_PUBLIC_CMS_ASSETS_URL}/`, 'g');

  const headData = cmsData?.map(tag => {
    if (
      (tag.attributes &&
        tag.attributes.name &&
        tag.attributes.name === 'twitter:image') ||
      (tag.attributes &&
        tag.attributes.property &&
        tag.attributes.property === 'og:image')
    ) {
      // eslint-disable-next-line no-param-reassign
      tag.attributes.content = tag.attributes.content.replace(
        regex,
        `${process.env.NEXT_PUBLIC_BASE_URL}/assets/cms/`
      );
    }

    return tag;
  });

  return headData;
};

const HeadComponent: FC<Props> = ({ cmsData, baseUrl, canonical, ...rest }) => {
  const router = useRouter();

  let headData = null;

  try {
    headData = renderMetaTags(proxyOgImages(cmsData));
  } catch (e) {
    console.error(e);
  }

  const titleEl = headData?.find(h => h.key === 'title');
  const titleText = titleEl?.props?.children ?? null;

  useEffect(() => {
    let timeout = null;

    const handleRouteChange = (url: string): void => {
      const ampBlArr = [
        ROUTE_PRODUCT,
        ROUTE_CART,
        `${ROUTE_BUILD}/${ROUTE_BUILD_PC}`,
      ];
      const isBlacklisted = ampBlArr.some(u => url.includes(u));

      if (!isBlacklisted) {
        timeout = setTimeout(() => {
          track('Page View', {
            page_url: window.location.href,
            page_title: titleText,
            page_path: url,
            page_domain: window.location.hostname,
            page_location: window.location.href,
          });
        }, 1);
      }
    };
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
      clearTimeout(timeout);
    };
  }, [router.events, titleText]);

  return (
    <>
      <NextSeo
        canonical={canonical}
        languageAlternates={buildLanguageAlternates(baseUrl, canonical)}
        {...rest}
      />
      {cmsData && <Head>{renderMetaTags(proxyOgImages(cmsData))}</Head>}
    </>
  );
};

export default memo(HeadComponent);
